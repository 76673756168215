
import { Component, Prop, Vue } from "vue-property-decorator";
import { Icon, Divider, Cell, CellGroup, ActionSheet } from "vant";

@Component({
  components: {
    [Icon.name]: Icon,
    [Divider.name]: Divider,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [ActionSheet.name]: ActionSheet,
  },
})
export default class HotelOrderInfo extends Vue {
  @Prop({ type: Object, default: () => ({}) }) hotelInfoData;
  public getDays(date) {
    return this.$moment(date).format("MM月DD日");
  }
  getJuliDate(date) {
    let num = this.$moment(date).diff(
      this.$moment().format("YYYY-MM-DD"),
      "days"
    );
    return num > 0 ? "离入住还有" + num + "天" : num == 0 ? "今天" : "";
  }

  public isShowChangeMap: boolean = false;
  public OS = "";
  public actionsMapList = [
    { name: "百度地图" },
    { name: "高德地图" },
    { name: "腾讯地图" },
  ];
  public onSelect(val) {
    let ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("like mac os x") > -1) this.OS = "IOS";
    if (ua.indexOf("android") > -1) this.OS = "Android";

    this.isShowChangeMap = false;

    if (val.name === "百度地图") {
      this.baidu(
        this.hotelInfoData.HotelAddress,
        this.hotelInfoData.Latitude,
        this.hotelInfoData.Longitude
      );
    }
    if (val.name === "高德地图") {
      this.GaoDe(
        this.hotelInfoData.HotelAddress,
        this.hotelInfoData.GDLatitude,
        this.hotelInfoData.GDLongitude
      );
    }
    if (val.name === "腾讯地图") {
      this.Tencent(
        this.hotelInfoData.HotelAddress,
        this.hotelInfoData.GDLatitude,
        this.hotelInfoData.GDLongitude
      );
    }
  }

  // 百度
  public baidu(query, lat, lon) {
    // 使用关键词搜索功能
    // https://lbsyun.baidu.com/index.php?title=uri
    if (this.OS === "IOS") {
      window.location.href = `baidumap://map/place/search?query=${query}&lat=${lat}&lon=${lon}`;
    } else if (this.OS === "Android") {
      window.location.href = `bdapp://map/place/search?query=${query}&lat=${lat}&lon=${lon}`;
    }
  }

  // 高德
  public GaoDe(poiname, lat, lon) {
    // 使用地图标注功能
    // https://lbs.amap.com/api/amap-mobile/summary
    if (this.OS === "IOS") {
      location.href = `iosamap://viewMap?sourceApplication=applicationName&poiname=${poiname}&lat=${lat}&lon=${lon}`;
    }
    if (this.OS === "Android") {
      location.href = `androidamap://viewMap?sourceApplication=appname&poiname=${poiname}&lat=${lat}&lon=${lon}`;
    }
  }

  // 腾讯
  public Tencent(keyword, lat, lon) {
    // 使用周边搜索功能
    // https://lbs.qq.com/webApi/uriV1/uriGuide/uriMobileRoute
    if (this.OS === "IOS") {
      location.href = `qqmap://map/search?keyword=${keyword}&lat=${lat}&lon=${lon}`;
    } else if (this.OS === "Android") {
      location.href = `qqmap://map/search?keyword=${keyword}&lat=${lat}&lon=${lon}`;
    }
  }
}
